import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { getProvinciasApi, listEstadoTributario } from "../../../requests/urls";
import request from "../../../requests/request";
import FormBaseCliProv from "../../../components/ait-reusable/FormBaseCliProv";
import ModalAddRecargo from "./RecargoCtaCte/ModalAddRecargo";
import ModalObservacion from "../../../components/ait-reusable/ModalObservacion";
import Alert from "@material-ui/lab/Alert";
import { getInformacionFiscal } from "../../Presupuesto/Consumidor/CargaRapidaCliente/utils";
import SearchIcon from "@material-ui/icons/Search";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import useDisclosure from "../../../customHooks/useDisclosure";
import { useSelector } from "react-redux";

export default function FormCliente({
  cliente,
  isEdicion,
  isSubmittingForm,
  isVendedor,
  arrayObservaciones,
  setArrayObservaciones,
  clienteInactivo,
  openModalCliInactivo,
  ...props
}) {
  const [estados, setEstados] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [openRecargo, setOpenRecargo] = useState(false);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const [recargos, setRecargos] = useState([]);
  const [recargoSelection, setRecargoSelection] = useState([]);
  const [updateRec, setUpdateRec] = useState(false); //Informa si se modificaron los recargos de un cliente
  const { impuestos: impuestos_boxer } = useSelector(
    (state) => state.loginReducer,
  );
  const formRef = React.useRef();
  const [openedBackdrop, { open: openBackdrop, close: closeBackdrop }] =
    useDisclosure(false);
  const recargosForm =
    recargoSelection.length === 0
      ? updateRec
        ? []
        : cliente.recargo
      : recargoSelection;
  useEffect(() => {
    getEstadosTributarios();
    getProvincias();
  }, []);

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstados(response.data);
    } catch (error) {
      console.error(error);
      setEstados([]);
    }
  };

  const getProvincias = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getProvinciasApi,
      });
      setProvincias(response.data);
    } catch (error) {
      console.error(error);
      setProvincias([]);
    }
  };

  const handleCloseModalObservaciones = () => {
    setOpenModalObservaciones(false);
  };

  /**
   * Carda la informacion de la response de la consulta de informacion fiscal
   * al estado de Formik mediando useRef
   */
  const setInformacionFiscalToForm = (res) => {
    const setFieldValue = formRef.current.setFieldValue;
    const data = res.data;

    setFieldValue("razonSocial", data.razon_social);
    setFieldValue("numeroDocumento", data.numero_documento);
    setFieldValue("domicilio", data.domicilio_fiscal.direccion);
    if (data.domicilio_fiscal.provincia_id) {
      setFieldValue("provincia", data.domicilio_fiscal.provincia_id);
    }

    let estadoTributario = estados.find(
      (et) => et.descripcion === data.estado_tributario,
    );

    if (estadoTributario !== -1) {
      setFieldValue("idEstadoTributario", estadoTributario.idEstadoTributario);
    }

    // La response solo envia lo impuestos que afectan al cliente
    // se hace un merge con los que maneja la sucursal para que figuren todos en el form
    let impuestos_cliente = impuestos_boxer.map((impuesto_boxer) => {
      const impuesto_cliente = data.impuestos.find(
        (imp) => imp.impuesto_id === impuesto_boxer.id,
      );
      return impuesto_cliente
        ? { ...impuesto_boxer, ...impuesto_cliente, activo: true }
        : //Se carga el IIBB defecto seleccionado
          {
            ...impuesto_boxer,
            activo:
              impuesto_boxer.tipo_actualizacion === "Manual" &&
              impuesto_boxer.nombre === "Percepción de IIBB",
          };
    });
    setFieldValue("impuestos", impuestos_cliente);
  };

  const validarCuit = async () => {
    openBackdrop();
    await getInformacionFiscal({
      dni: null,
      cuit: formRef.current.values.CUIT,
      handleResponse: setInformacionFiscalToForm,
      setLoading: () => {},
    });
    closeBackdrop();
  };

  return (
    <div>
      {/* <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={() => setOpen(true)}
        transitionDuration={transition}
      >
        <CircularProgress color="inherit" />
      </Backdrop>  */}

      {console.log(cliente)}

      <Formik
        enableReinitialize={true}
        initialValues={{
          ...cliente,
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          razonSocial: Yup.string().required("Ingrese nombre o razón social."),
          provincia: Yup.number()
            .required("Seleccione una provincia.")
            .nullable(),
          CUIT: Yup.number()
            .min(9999999999, "El CUIT ingresado no es válido.")
            .max(99999999999, "El CUIT ingresado no es válido.")
            .when("idEstadoTributario", {
              is: (value) =>
                value && (value === 1 || value === 2 || value === 3),
              then: Yup.number().required("Ingrese un nro. CUIT válido."),
            }),
          numeroDocumento: Yup.number()
            .min(0, "El nro de documento no es válido.")
            .max(99999999, "El nro de documento no es válido.")
            .when("idEstadoTributario", {
              is: (value) => value && value === 5,
              then: Yup.number().required(
                "Ingrese un nro. de documento válido.",
              ),
            })
            .nullable(),
          correoElectronico: Yup.string()
            .nullable()
            .when("envio_automatico_comprobante_venta", {
              is: true,
              then: Yup.string().required(
                "El correo electrónico es obligatorio para el envio de comprobantes.",
              ),
              otherwise: Yup.string().nullable(),
            }),
          comision: Yup.number()
            .min(0, "La comision no es válida.")
            .max(100, "La comision no es válida."),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting },
        ) => {
          try {
            props.handleSubmit(values, recargoSelection, updateRec);
          } catch (err) {
            setErrors({ submit: err.message });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form
            style={{ width: "100%", paddingTop: "10px" }}
            onSubmit={handleSubmit}
            {...props}
          >
            <FormBaseCliProv
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              estados={estados}
              provincias={provincias}
              isCliente={true}
              recargo={{ openRecargo, setOpenRecargo }}
              recargosForm={recargosForm}
              isVendedor={isVendedor}
              setOpenModalObservaciones={setOpenModalObservaciones}
              setFieldValue={setFieldValue}
              cliente={cliente}
              validarCuit={validarCuit}
            />

            {clienteInactivo && !openModalCliInactivo && (
              <Grid container className="justify-content-center mt-3">
                <Grid item>
                  <Alert severity="info">
                    Los datos del cliente inactivo se modificarán por estos
                    nuevos datos.
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid container className="justify-content-center mt-3">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmittingForm}
                  style={{ backgroundColor: "#17c671", color: "#fff" }}
                >
                  {isEdicion
                    ? "GUARDAR CAMBIOS"
                    : clienteInactivo
                      ? "ACTIVAR Y MODIFICAR"
                      : "REGISTRAR"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {openRecargo && (
        <ModalAddRecargo
          open={openRecargo}
          toggle={() => setOpenRecargo(false)}
          dataRecargos={recargos}
          addRecargos={(newRecargos) => {
            setRecargos(newRecargos);
            setOpenRecargo(false);
            setUpdateRec(true);
          }}
          selection={recargoSelection}
          setSelection={setRecargoSelection}
          cliente={cliente}
        />
      )}

      {openModalObservaciones && (
        <ModalObservacion
          clienteSelected={cliente}
          open={openModalObservaciones}
          handleClose={handleCloseModalObservaciones}
          arrayObservaciones={arrayObservaciones}
          setArrayObservaciones={setArrayObservaciones}
        />
      )}

      <CircularBackdrop openBackdrop={openedBackdrop} />
    </div>
  );
}
