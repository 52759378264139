import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Checkbox,
  Box,
  IconButton,
  Divider,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import validateValuesForm from "./utils";
import { readDescuentos } from "../../../requests/urls";
import request from "../../../requests/request";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import useConversionNumeroMiles from "../../../customHooks/useConversionNumeroMiles";
import MedioPagoCliente from "../../../views/Clientes/Components/MedioPagoCliente";
import { tienePermisos } from "../../../utils/tienePermisos";
import { PERMISOS } from "../../../constantes/permisos";
import { getTiposClienteService } from "../../../services/tiposClientes";
import { FACTURA_POR_DEFECTO } from "../../../views/Clientes/utils";
import { esUsuarioAit } from "../../../utils/esUsuarioAit";
import ObservacionesProveedor from "../ObservacionesProveedor";
import ModalListaDePrecios from "../ModalListaDePrecios";
import { formDefaultListaDePrecios } from "../../../views/Proveedores/NuevoFormProveedor/FormDefault";
import { useGetSucursal } from "../../../customHooks/useGetSucursal";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";
import HelpIcon from "@material-ui/icons/Help";
import Alert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  borderGrid: {
    borderLeft: "1px solid #abbaab",
  },
}));

export default function FormBaseCliProv({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  estados,
  isCliente,
  recargo,
  provincias = [],
  recargosForm,
  children,
  isVendedor,
  setOpenModalObservaciones,
  setFieldValue,
  cliente,
  proveedor,
  arrayObservaciones,
  setArrayObservaciones,
  validarCuit = () => {},
}) {
  const { sucursal } = useGetSucursal();
  const [descuentos, setDescuentos] = useState([]);
  const [modalObservacion, setModalObservacion] = useState(false);
  const [modalNuevaListaDePrecios, setModalNuevaListaDePrecios] =
    useState(false);

  const widthMinGrid = useMediaQuery("(min-width:995px)"); //True si el width es mayor a 995px
  const widthMaxButton = useMediaQuery("(min-width:1034px)"); //True si el width es mayor a 1034px
  const classes = useStyles();

  const {
    aplica_percepcion_iibb,
    aplica_percepciones_impuesto_municipal,
    empleado,
  } = useSelector((state) => state.loginReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const { conversionNumero } = useConversionNumeroMiles();
  const tienePermisosCliente = tienePermisos(empleado, PERMISOS.CLIENTES);
  const [tiposCliente, setTiposCliente] = useState([]);

  const handleGetDescuentos = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readDescuentos,
      });
      response.status !== 204 && setDescuentos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetTiposCliente = async () => {
    try {
      const response = await getTiposClienteService();
      setTiposCliente(response);
      const tipoPorDefecto = response.find((tipo) => tipo.is_default === true);
      setFieldValue("tipo_cliente", tipoPorDefecto ? tipoPorDefecto.id : null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetProvinciaDefault = () => {
    if (
      !sucursal ||
      !provincias.length ||
      values.provincia ||
      !sucursal.provincia
    )
      return;

    const provinciaSucursal = provincias.find(
      (provincia) => provincia.id === sucursal.provincia.id,
    );

    setFieldValue("provincia", provinciaSucursal.id);
  };

  useEffect(() => {
    handleSetProvinciaDefault();
  }, [sucursal, provincias, values.provincia]);

  useEffect(() => {
    handleGetDescuentos();
    setFieldValue && handleGetTiposCliente();
    if (proveedor && proveedor.parametros.length == 0) {
      setFieldValue("parametros", formDefaultListaDePrecios);
    }
  }, []);

  const agregarElemento = (element) => {
    let aux_list = [...values.parametros];
    element["id"] = (aux_list.length + 1) * -1;
    aux_list.push(element);
    setFieldValue("parametros", aux_list);
    setModalNuevaListaDePrecios(false);
  };

  return (
    <Grid container spacing={2} className="pb-2">
      <Grid
        item
        lg={isCliente && !values.es_comision ? 9 : 12}
        md={isCliente && !values.es_comision ? (widthMinGrid ? 9 : 12) : 12}
        xs={12}
      >
        <Grid container spacing={3}>
          <Grid item lg={isCliente ? 8 : 6} xs={12} md={isCliente ? 8 : 6}>
            <TextField
              name="CUIT"
              error={Boolean(touched.CUIT && errors.CUIT)}
              style={{ backgroundColor: "white" }}
              fullWidth
              size="small"
              inputProps={{
                maxLength: 11,
              }}
              label={
                values.idEstadoTributario === 1 ||
                values.idEstadoTributario === 2 ||
                values.idEstadoTributario === 3
                  ? "CUIT *"
                  : "CUIT"
              }
              value={values.CUIT || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.CUIT && errors.CUIT}
              variant="outlined"
            />
          </Grid>
          {isCliente && (
            <Grid item lg={4} xs={12} md={4}>
              <Button
                variant="outlined"
                color="primary"
                // style={{
                //   fontWeight: "bolder",
                // }}
                // size="small"
                onClick={validarCuit}
                disableElevation
                fullWidth
                startIcon={<SearchIcon />}
              >
                Buscar datos
              </Button>
            </Grid>
          )}
          <Grid item lg={4} xs={12} md={4}>
            <TextField
              size="small"
              autoFocus
              name="razonSocial"
              error={Boolean(touched.razonSocial && errors.razonSocial)}
              style={{ backgroundColor: "white" }}
              fullWidth
              label="Nombre / Razón social *"
              value={values.razonSocial || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              helperText={touched.razonSocial && errors.razonSocial}
            />
          </Grid>

          <Grid item lg={isCliente ? 4 : 6} xs={12} md={isCliente ? 4 : 6}>
            <TextField
              name="numeroDocumento"
              error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
              style={{ backgroundColor: "white" }}
              fullWidth
              size="small"
              inputProps={{
                maxLength: 8,
              }}
              label={
                (isCliente && values.idEstadoTributario === 5) ||
                (!isCliente &&
                  (values.idEstadoTributario === 4 ||
                    values.idEstadoTributario === 5))
                  ? "Número documento *"
                  : "Número documento"
              }
              value={values.numeroDocumento || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              helperText={touched.numeroDocumento && errors.numeroDocumento}
            />
          </Grid>

          <Grid item lg={4} xs={12} md={4}>
            <TextField
              name="responsable_cuenta"
              size="small"
              error={Boolean(
                touched.responsable_cuenta && errors.responsable_cuenta,
              )}
              style={{ backgroundColor: "white" }}
              fullWidth
              label="Responsable de cuenta"
              value={values.responsable_cuenta || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              helperText={
                touched.responsable_cuenta && errors.responsable_cuenta
              }
            />
          </Grid>

          {!isCliente && (
            <Grid item lg={4} xs={12} md={4}>
              <TextField
                name="alias"
                size="small"
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Alias proveedor"
                value={values.alias || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                helperText={touched.alias && errors.alias}
                error={Boolean(touched.alias && errors.alias)}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
          )}

          <Grid item lg={isCliente ? 4 : 6} xs={12} md={isCliente ? 4 : 6}>
            <TextField
              name="idEstadoTributario"
              style={{ backgroundColor: "white" }}
              select
              size="small"
              fullWidth
              label="Estado tributario *"
              value={values.idEstadoTributario || ""}
              onChange={handleChange}
              variant="outlined"
            >
              {estados.map((estado, index) => (
                <MenuItem
                  key={index}
                  index={index}
                  value={estado.idEstadoTributario}
                >
                  {estado.descripcion}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item lg={isCliente ? 4 : 6} xs={12} md={isCliente ? 4 : 6}>
            <TextField
              size="small"
              name="correoElectronico"
              error={Boolean(
                touched.correoElectronico && errors.correoElectronico,
              )}
              style={{ backgroundColor: "white" }}
              fullWidth
              label="Correo electronico"
              value={values.correoElectronico || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              helperText={touched.correoElectronico && errors.correoElectronico}
            />
          </Grid>

          <Grid item lg={isCliente ? 3 : 6} xs={12} md={isCliente ? 4 : 6}>
            <TextField
              name="telefonoContacto"
              style={{ backgroundColor: "white" }}
              fullWidth
              size="small"
              label="Tel. contacto"
              value={values.telefonoContacto || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={6} xs={12} md={6}>
            <TextField
              name="domicilio"
              style={{ backgroundColor: "white" }}
              fullWidth
              size="small"
              label="Domicilio"
              value={values.domicilio || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={isCliente ? 3 : 6} xs={12} md={isCliente ? 3 : 6}>
            <TextField
              name="provincia"
              error={Boolean(touched.provincia && errors.provincia)}
              style={{ backgroundColor: "white" }}
              select
              size="small"
              fullWidth
              label="Provincia *"
              value={values.provincia || ""}
              onChange={handleChange}
              variant="outlined"
              helperText={touched.provincia && errors.provincia}
            >
              {provincias.map((provincia, index) => (
                <MenuItem key={index} index={index} value={provincia.id}>
                  {provincia.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {isCliente && (
            <Grid item lg={4} xs={12} md={3}>
              <TextField
                size="small"
                name="deudaMaxima"
                style={{ backgroundColor: "white" }}
                fullWidth
                label="Deuda máxima"
                value={values.deudaMaxima || ""}
                onChange={(event) => {
                  let newValue = conversionNumero(event.target.value);
                  setFieldValue("deudaMaxima", newValue);
                }}
                onBlur={handleBlur}
                variant="outlined"
                // onInput={validateValuesForm}
                InputProps={{
                  endAdornment: (
                    <TooltipMoreInfoMaterial
                      titleTooltip={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Si la deuda máxima es igual a cero, se considera que
                          no tiene limitante.
                        </h6>
                      }
                      color="#000"
                      position={"top"}
                    />
                  ),
                }}
              />
            </Grid>
          )}

          {isCliente && (
            <Grid item lg={4} xs={12} md={3}>
              <TextField
                size="small"
                name="dias_deuda"
                style={{ backgroundColor: "white" }}
                placeholder="Ej: 30"
                fullWidth
                label="Días máximos de deuda"
                value={values.dias_deuda === null ? "" : values.dias_deuda}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                onInput={validateValuesForm}
                InputProps={{
                  endAdornment: (
                    <TooltipMoreInfoMaterial
                      titleTooltip={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Si desea que la cantidad de días no sea un limitante,
                          debe dejar el campo vacío.
                        </h6>
                      }
                      color="#000"
                      position={"top"}
                    />
                  ),
                }}
              />
            </Grid>
          )}

          {isCliente ? (
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
                name="tipo_cliente"
                value={values.tipo_cliente || null}
                label="Tipo de cliente"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {tiposCliente.length > 0 &&
                  tiposCliente.map((tipoCliente) => {
                    return (
                      <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                        {tipoCliente.nombre}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>
          ) : null}

          {isCliente && (
            <>
              <Grid item lg={4} xs={12} md={4}>
                <TextField
                  style={{ backgroundColor: "white" }}
                  select
                  fullWidth
                  size="small"
                  label="Descuento"
                  InputLabelProps={{ shrink: true }}
                  value={values.id_descuento || " "}
                  onChange={handleChange}
                  variant="outlined"
                  name="id_descuento"
                >
                  <MenuItem selected value=" ">
                    Sin descuento
                  </MenuItem>
                  {descuentos.map((option) => (
                    <MenuItem
                      key={option.id_descuento}
                      value={option.id_descuento}
                      name={option.nombre}
                    >
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={4}>
                <MedioPagoCliente
                  value={values.id_medio_pago}
                  valueCuentaCorriente={values.cuenta_corriente}
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item lg={4} xs={12} md={4}>
                <TextField
                  name="comprobantePorDefecto"
                  error={Boolean(
                    touched.comprobantePorDefecto &&
                      errors.comprobantePorDefecto,
                  )}
                  style={{ backgroundColor: "white" }}
                  select
                  size="small"
                  fullWidth
                  label="Comprobante por defecto *"
                  value={values.comprobantePorDefecto || ""}
                  onChange={handleChange}
                  variant="outlined"
                  helperText={
                    touched.comprobantePorDefecto &&
                    errors.comprobantePorDefecto
                  }
                >
                  {FACTURA_POR_DEFECTO.map((tipo, index) => (
                    <MenuItem key={index} index={index} value={tipo.key}>
                      {tipo.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item lg={values.es_comision ? 3 : 6} xs={12} md={4}>
                <FormControlLabel
                  className="m-0"
                  control={
                    <Switch
                      color="primary"
                      checked={values.es_comision || false}
                      onChange={handleChange}
                      name="es_comision"
                    />
                  }
                  label="Comision"
                />
              </Grid>

              {values.es_comision && (
                <Grid item lg={3} xs={12} md={4}>
                  <TextField
                    name="comision"
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    label="% Comision"
                    size="small"
                    value={values.comision || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.es_comision}
                    variant="outlined"
                  />
                </Grid>
              )}
              {tienePermisosCliente && (
                <Grid item lg={6} xs={12} md={4} style={{ paddingLeft: "2em" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={values.cuenta_corriente || false}
                        name="cuenta_corriente"
                        onBlur={handleBlur}
                        color="primary"
                      />
                    }
                    label={
                      !values.cuenta_corriente
                        ? "Activar cuenta corriente"
                        : "Desactivar cuenta corriente"
                    }
                  />
                </Grid>
              )}

              {isCliente && (
                <Grid item lg={6} xs={12} md={6} style={{ paddingLeft: "2em" }}>
                  <Box
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={
                            values.envio_automatico_comprobante_venta || false
                          }
                          name="envio_automatico_comprobante_venta"
                          onBlur={handleBlur}
                          color="primary"
                        />
                      }
                      label={
                        "Envio automático de comprobante de venta por email"
                      }
                    />
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          El comprobante se enviará automáticamente al correo
                          electrónico que has configurado, incluso si no
                          seleccionas la opción de enviar correo al confirmar la
                          venta.
                        </h6>
                      }
                      placement={"bottom"}
                    >
                      <IconButton aria-label="delete">
                        <HelpIcon />
                      </IconButton>
                    </TooltipWithoutIcon>
                  </Box>
                </Grid>
              )}

              {isCliente && (
                <Grid item lg={6} xs={12} md={6} style={{ paddingLeft: "2em" }}>
                  <Box
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={
                            values.envio_automatico_recibo_pagos_billetera ||
                            false
                          }
                          name="envio_automatico_recibo_pagos_billetera"
                          onBlur={handleBlur}
                          color="primary"
                        />
                      }
                      label={
                        "Envio automático de comprobante de recibo de pago por email"
                      }
                    />
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Indica si se envia automaticamente el comprobante de
                          recibo de pago en billetera al cliente al mail
                          registrado
                        </h6>
                      }
                      placement={"bottom"}
                    >
                      <IconButton aria-label="delete">
                        <HelpIcon />
                      </IconButton>
                    </TooltipWithoutIcon>
                  </Box>
                </Grid>
              )}

              {isCliente && (
                <Grid item lg={6} xs={12} md={6} style={{ paddingLeft: "2em" }}>
                  <Box
                    style={{
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={
                            values.forzar_medio_pago_contado_pdf || false
                          }
                          name="forzar_medio_pago_contado_pdf"
                          onBlur={handleBlur}
                          color="primary"
                        />
                      }
                      label={"Ocultar medio de pago en comprobantes de ventas"}
                    />
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Al activar esta opción, los comprobantes de venta del
                          cliente mostrarán "Contado" como medio de pago,
                          independientemente del medio de pago real que se haya
                          elegido.
                        </h6>
                      }
                      placement={"bottom"}
                    >
                      <IconButton aria-label="delete">
                        <HelpIcon />
                      </IconButton>
                    </TooltipWithoutIcon>
                  </Box>
                </Grid>
              )}

              {values.impuestos && values.impuestos.length > 0 && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h6">Impuestos</Typography>
                    <Divider />
                    <Alert severity="info" style={{ marginTop: 8 }}>
                      <Typography variant="body2">
                        Los impuestos que se encuentran seleccionados se
                        aplicarán a las ventas realizadas al cliente.
                      </Typography>
                    </Alert>
                  </Grid>
                  {values.impuestos.map((impuesto, index) => (
                    <Grid
                      item
                      lg={6}
                      xs={12}
                      md={6}
                      style={{ paddingLeft: "2em" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            checked={values.impuestos[index].activo}
                            name={`impuestos[${index}].activo`}
                            onBlur={handleBlur}
                            color="primary"
                            disabled={impuesto.tipo_actualizacion !== "Manual"}
                          />
                        }
                        label={`Aplica ${
                          impuesto.nombre
                        } - ${impuesto.alicuota || ""}%`}
                      />
                    </Grid>
                  ))}
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenModalObservaciones(true)}
                >
                  Ver observaciones del cliente
                </Button>
              </Grid>
            </>
          )}

          {!isCliente && (
            <>
              <Grid container spacing={2} style={{ paddingLeft: 10 }}>
                <Grid item sm={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={values.is_precio_lista || ""}
                        onChange={handleChange}
                        name="is_precio_lista"
                      />
                    }
                    label="Precio Lista"
                  />
                </Grid>

                <Grid item sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={values.calcular_retencion || false}
                        onChange={handleChange}
                        name="calcular_retencion"
                        disabled={values.deshabilitar_calcular_retencion}
                      />
                    }
                    label={
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        alignContent={"center"}
                        gridGap={4}
                      >
                        Calcular retención{" "}
                        <TooltipMoreInfoMaterial
                          position={"top"}
                          titleTooltip={
                            <h6
                              style={{
                                fontSize: 15,
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              Este campo autoriza el cálculo automático de las
                              retenciones en los pagos. Una vez activado, no se
                              podrá desactivar.
                            </h6>
                          }
                          color={"black"}
                        />
                      </Box>
                    }
                  />
                </Grid>

                <Grid item sm={3} hidden={!values.is_precio_lista}>
                  <Button
                    style={{
                      padding: widthMinGrid && !widthMaxButton && "3px",
                      outline: "none",
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => setModalNuevaListaDePrecios(true)}
                    disabled={
                      values.parametros.filter(
                        (i) => i.is_precio_lista && i.is_active,
                      ).length >= configGeneral.limite_lista_precios
                    }
                  >
                    Agregar Lista de Precios
                  </Button>
                </Grid>
                <Grid
                  item
                  sm={4}
                  hidden={
                    !values.is_precio_lista ||
                    !(
                      values.is_precio_lista &&
                      values.parametros.filter(
                        (i) => i.is_precio_lista && i.is_active,
                      ).length > configGeneral.limite_lista_precios
                    )
                  }
                >
                  <span
                    className={"d-flex font-weight-bold"}
                    style={{ color: "red" }}
                  >
                    <i
                      className={"material-icons mr-1"}
                      style={{ fontSize: "25px" }}
                    >
                      error
                    </i>
                    <p className="mt-1">
                      El Proveedor supera el limite de Listas de Precios
                      permitidas. Por favor, elimine alguna Lista de Precios
                      para poder Continuar.
                    </p>
                  </span>
                </Grid>
              </Grid>
            </>
          )}

          {children}

          {!isCliente && (
            <Grid item sm={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setModalObservacion(true)}
              >
                OBSERVACIONES DEL PROVEEDOR
              </Button>
            </Grid>
          )}

          {!isCliente && esUsuarioAit(empleado) ? (
            <Grid item sm={3} style={{ textAlign: "start" }}>
              <TextField
                name={"identificador"}
                style={{ backgroundColor: "white" }}
                fullWidth
                size="small"
                label="Identificador para imágenes"
                value={values.identificador || ""}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          ) : null}
          {!isCliente ? (
            <Grid item sm={3} style={{ textAlign: "start" }}>
              <TextField
                name={"numero"}
                style={{ backgroundColor: "white" }}
                fullWidth
                size="small"
                label="Número de proveedor"
                value={values.numero || ""}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {modalObservacion ? (
        <ObservacionesProveedor
          open={modalObservacion}
          proveedor={proveedor}
          tittle={"Observaciones del proveedor"}
          // ocultarAgregarObservacion={true}
          idProveedor={proveedor.idProveedor}
          setOpen={setModalObservacion}
          arrayObservaciones={arrayObservaciones}
          setArrayObservaciones={setArrayObservaciones}
        />
      ) : null}

      {modalNuevaListaDePrecios ? (
        <ModalListaDePrecios
          open={modalNuevaListaDePrecios}
          proveedor={proveedor}
          setOpen={setModalNuevaListaDePrecios}
          saveElement={(e) => agregarElemento(e)}
        />
      ) : null}

      {isCliente && !values.es_comision && (
        <Grid
          item
          lg={3}
          md={widthMinGrid ? 3 : 12}
          xs={12}
          className={widthMinGrid ? classes.borderGrid : ""}
        >
          <Grid container spacing={2} style={{ textAlign: "center" }}>
            <Grid item xs={12} md={12} lg={12}>
              <h6 className="mb-0">DESCUENTOS POR PLAZO:</h6>

              {recargosForm && recargosForm.length > 0 ? (
                <List dense={false} className="pb-2">
                  {recargosForm.map(
                    (x, i) =>
                      x.es_visible && (
                        <ListItem key={i} className="p-0">
                          <ListItemText
                            className="text-center"
                            primary={x.dias + " días, recargo de " + x.nombre}
                          />
                        </ListItem>
                      ),
                  )}
                </List>
              ) : (
                <label style={{ fontSize: 16 }}>Sin descuentos</label>
              )}

              <Grid item xs={12} md={12} lg={12}>
                <Button
                  style={{
                    padding: widthMinGrid && !widthMaxButton && "3px",
                    outline: "none",
                  }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<TrendingUpIcon />}
                  onClick={() => recargo.setOpenRecargo(!recargo.openRecargo)}
                >
                  Elegir descuentos
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
